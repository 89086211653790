
import { computed, defineComponent, onBeforeMount, reactive } from 'vue'
import { useRoute } from 'vue-router'

// components
import SBreadCrumbs from '@/common/components/SBreadCrumbs/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import ExpressStatus from '@/modules/HistoryNew/components/ExpressStatus/index.vue'
import SIconButton from '@/common/components/SIconButton/index.vue'

import iArrowLeft from '@/assets/icons/ArrowLeft.svg'
import iRefresh from '@/assets/icons/Refresh.svg'
import iLocation from '@/assets/icons/Location.svg'
import iCancel from '@/assets/icons/Cancel.svg'

// types
import { DeliveryOrderT } from '@/core/types/Delivery.types'

// composable
import useNotifications from '@/common/composable/useNotifications'

// realisastions
import { DeliveryRealisation } from '@/core/realisations/Delivery.realisation'

export default defineComponent({
    components: {
        's-bread-crumbs': SBreadCrumbs,
        's-loader': SLoader,
        's-button': SButton,
        'icon-arrow-left': iArrowLeft,
        'icon-refresh': iRefresh,
        'icon-location': iLocation,
        's-icon-button': SIconButton,
        'express-status': ExpressStatus,
        iCancel,
    },
    setup() {
        const notifications = useNotifications()
        const delivery = new DeliveryRealisation()

        const route = useRoute()
        const order_id = Number(route.params.order_id)

        const state = reactive({
            is_loading: false,
            is_failed: false,
            order: null as DeliveryOrderT | null,
            is_status_loading: false,
        })

        const loadExpressOrder = async () => {
            try {
                state.is_loading = true

                const response = await delivery.getExpressOrder(order_id)
                state.order = response.data

                state.is_failed = false
            } catch (error) {
                notifications.failure(
                    {
                        title: 'При получении данных о заказе произошла ошибка',
                        group: 'create-module',
                        type: 'error',
                    },
                    error
                )
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        const refreshStatus = async () => {
            try {
                state.is_status_loading = true

                await delivery.refreshStatus(order_id)
                notifications.success({
                    title: 'Статус обновлён',
                    group: 'create-module',
                    type: 'success',
                })

                loadExpressOrder()
            } catch (error) {
                notifications.failure(
                    {
                        title: 'При обновлении статуса произошла ошибка',
                        type: 'error',
                        group: 'create-module',
                    },
                    error
                )
            } finally {
                state.is_status_loading = false
            }
        }

        const cancelOrder = async () => {
            const answer = confirm('Вы действительно хотите отменить заказ?')
            if (!answer) return

            try {
                state.is_status_loading = true

                await delivery.cancelOrder(order_id)
                notifications.success({
                    title: 'Заказ отменен',
                    group: 'create-module',
                    type: 'success',
                })

                loadExpressOrder()
            } catch (error) {
                notifications.failure(
                    {
                        title: 'При отмене заказа произошла ошибка',
                        type: 'error',
                        group: 'create-module',
                    },
                    error
                )
            } finally {
                state.is_status_loading = false
            }
        }

        const breadcrumbsLinks = [
            {
                id: 1,
                name: 'Доставка за 3 часа',
                path: '/history',
            },
            {
                id: 2,
                name: route.params.order_id,
                path: `/express/${route.params.order_id}`,
            },
        ]

        onBeforeMount(loadExpressOrder)

        const isCanCancel = computed(
            () => state.order && (state.order.status === 1 || state.order.status === 2)
        )

        return {
            state,
            loadExpressOrder,
            breadcrumbsLinks,
            refreshStatus,
            cancelOrder,
            //
            isCanCancel,
        }
    },
})
